import * as React from "react";
import Layout from "../components/Layout";
import ProductsSelection from "../components/ProductsSelection";
import { Link } from "gatsby";
import { Check } from "../components/svg";
import Seo from "../components/Seo";
import AreasSelection from "../components/AreasSelection";
import StripesDecoration from "../components/StripesDecoration";

import { Container } from "@mui/material";

const HomePage = () => (
  <Layout isHomepage>
    <Seo title="Home" />

    <>
      {/* value proposition */}
      <div className="bg-white">
        <StripesDecoration />
        <Container maxWidth="xl" sx={{ py: 15 }}>
          <h2 className="text-center text-blue-800 text-3xl leading-tight sm:text-5xl sm:leading-snug lg:px-24">
            We help Governments and companies to execute Digital Transformation
          </h2>

          <ul className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8 sm:text-2xl mt-12 sm:mt-24">
            {valueData.map(function (data) {
              return (
                <li key={data.id} className="flex items-start">
                  <div>
                    <Check className="w-auto h-6 sm:h-10 mt-px" />
                  </div>
                  <p
                    className="ml-3 sm:ml-6"
                    dangerouslySetInnerHTML={{ __html: data.title }}
                  ></p>
                </li>
              );
            })}
          </ul>

          <div className="text-center mt-12 sm:mt-24">
            <a
              rel="noreferrer"
              href="https://www.youtube.com/watch?v=-BViEsdqisU"
              target="_blank"
              className="inline-block border-2 border-blue-800 bg-blue-800 text-white font-bold w-full sm:w-96 p-5 sm:py-5 mt-2 sm:mt-0 sm:text-xl sm:px-12 sm:mx-2"
            >
              Watch video introduction
            </a>
            <Link
              to="/about/"
              className="inline-block border-2 border-blue-800 bg-white text-blue-800 font-bold w-full sm:w-96 p-5 sm:py-5 mt-2 sm:mt-4 xl:mt-0 sm:text-xl sm:px-12 sm:mx-2"
            >
              Learn more about us
            </Link>
          </div>
        </Container>
      </div>
      {/* products */}
      <div className="bg-yellow-800">
        <Container maxWidth="xl" sx={{ py: 15 }}>
          <h2 className="text-center text-gray-800 text-3xl sm:text-6xl leading-tight sm:leading-snug">
            Primary Products & Services
          </h2>

          <ProductsSelection />
        </Container>
      </div>

      {/* business areas */}
      <Container maxWidth="xl" sx={{ mt: 15, mb: 20 }}>
        <h2 className="text-center text-blue-800 text-3xl leading-tight sm:text-5xl sm:leading-snug">
          Business areas we are focusing on
        </h2>
        <AreasSelection />
      </Container>
    </>
  </Layout>
);

const valueData = [
  {
    id: 0,
    title: "Providing <strong>digital identification and signing</strong>",
  },
  {
    id: 1,
    title:
      "Designing proof based and reliable <strong>data governance systems</strong> that operate at low risk",
  },
  {
    id: 2,
    title:
      "Enabling <strong>interoperability</strong> between IT systems and registries",
  },
  {
    id: 3,
    title:
      " Turning trust-based solutions into <strong>proof-based platform</strong> by implementing Proof Technologies incl. Blockchain or other DLTs",
  },
  {
    id: 4,
    title:
      "Securing the <strong>compliance</strong> of data systems with the surrounding regulatory environment (eg. GDPR, AML)",
  },
  {
    id: 5,
    title:
      "Assessing the <strong>attack surface</strong> of the current IT system and infrastructure",
  },
];

export default HomePage;
